fetch("/api/config")
  .then((res) => res.json())
  .then((config) => {
    console.log("config", config);
    (window as any).config = config; // Needs typing, and not store in window?
    (window as any).MF_MINITR_URL = config.blobUrl;
    (window as any).MF_COMPONENTS_URL = config.componentsBlobUrl;
    (window as any).MF_UTILS_URL = config.utilBlobUrl;
    (window as any).MF_HEADER_URL = config.headerBlobUrl;
  })
  .finally(() => {
    import("./realIndex");
  });
